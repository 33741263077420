import { Injectable, Signal, WritableSignal, computed, signal } from "@angular/core";

export type ChatBoxContentId = "messages" | "personal-info" | "score-form";

/** A service used to define which type of content should be displayed in the chat box container */
@Injectable({ providedIn: "root" })
export class ChatBoxContentSwitcher {
  private _id: WritableSignal<ChatBoxContentId> = signal("messages");
  /** Whether the messages pane is displayed */
  public readonly areMessagesDisplayed: Signal<boolean> = computed(() => this._id() === "messages");

  /** @return The current content ID to be displayed */
  get id(): Signal<ChatBoxContentId> {
    return this._id;
  }

  /** Defines the new content ID */
  request(newId: ChatBoxContentId): void {
    this._id.set(newId);
  }

  /** Restores the content ID to the default one */
  release(): void {
    this._id.set("messages");
  }
}
