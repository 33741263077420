import { Inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Subject, debounceTime, filter } from "rxjs";
import { APP_CONFIG, AppConfig } from "../config";

/**
 * The global service use to centralize all requests to focus on HTML
 * elements, and to actually focus on those elements.
 */
@Injectable({ providedIn: "root" })
export class FocusRequestExecutor {
  private readonly focusRequests = new Subject<HTMLElement>();

  constructor(@Inject(APP_CONFIG) appConfig: AppConfig) {
    this.focusRequests
      .pipe(
        filter((element) => !!element),
        debounceTime(appConfig.focusRequestsDelay),
        takeUntilDestroyed()
      )
      .subscribe((element) => element.focus({ preventScroll: true }));
  }

  /** Requests the passed element to be focused */
  public next(element: HTMLElement) {
    this.focusRequests.next(element);
  }
}
