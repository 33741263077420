<form
  class="d-flex flex-column justify-content-between px-4 py-3"
  style="flex: 1"
  aria-label="-"
  i18n-aria-label="@@score-form.form.label"
  [formGroup]="scoreForm"
  (ngSubmit)="onSubmit()"
  [attr.aria-hidden]="!availableForAria()"
>
  <!-- Page 1/2 -->
  <fof-score-form-part1
    *ngIf="activeStepId === 0"
    [scoreForm]="scoreForm.controls.part1"
  ></fof-score-form-part1>

  <!-- Page 2/2 -->
  <fof-score-form-part2
    *ngIf="activeStepId === 1"
    [scoreForm]="scoreForm.controls.part2"
  ></fof-score-form-part2>

  <div class="d-flex flex-column">
    <mat-error role="alert" *ngIf="scoreForm.invalid && !scoreForm.pristine" i18n="@@field.invalid"
      >-</mat-error
    >
    <div class="d-flex justify-content-center align-items-center my-2">
      <button
        icon="chevron-left"
        [etched]="buttonsStyles"
        title="-"
        i18n-title="@@carousel.gotToPreviousSlide.title"
        class="ms-1"
        (click)="activateStep(activeStepId - 1)"
        [disabled]="activeStepId === 0"
        data-testid="previousStepButton"
        type="button"
      ></button>
      <ng-container *ngFor="let n of [0, 1]; let itemIndex = index">
        <button
          point
          [class.active]="itemIndex === activeStepId"
          (click)="activateStep(itemIndex)"
          [class.aria-current]="itemIndex === activeStepId"
          data-testid="slide-button"
        ></button>
      </ng-container>
      <button
        icon="chevron-right"
        [etched]="buttonsStyles"
        title="-"
        i18n-title="@@carousel.gotToNextSlide.title"
        class="ms-1"
        (click)="activateStep(activeStepId + 1)"
        [disabled]="activeStepId === 1"
        data-testid="nextStepButton"
        type="button"
      ></button>
    </div>
    <button
      class="my-1"
      formButton="primary"
      type="submit"
      [disabled]="scoreForm.invalid"
      i18n="@@button.send"
      data-testid="submit"
    >
      -
    </button>

    <button
      formButton="primary-text"
      role="cancel"
      type="reset"
      (click)="onCancel()"
      i18n="@@button.close"
      data-testid="cancel"
    >
      -
    </button>
  </div>
</form>
