import { finalize, mergeMap } from "rxjs";
import { Inject, Injectable } from "@angular/core";
import { OutgoingMessage, EvaluationOutgoingMessage } from "app/chat/outgoing";
import { ConversationIdStore } from "./conversation-id.store";
import { ConversationId } from "./conversation-id.model";
import { ConversationApiClient } from "./conversation.apiclient";
import { MessagesFetcher } from "./messages.fetcher";
import { MESSAGES_FETCHER } from "./di-tokens";

/** Service used to send messages to the backend server */
@Injectable({ providedIn: "root" })
export class MessageSender {
  constructor(
    private conversationIdStore: ConversationIdStore,
    private conversationApiClient: ConversationApiClient,
    @Inject(MESSAGES_FETCHER) private messagesFetcher: MessagesFetcher
  ) {}

  /** Adds a new message to the conversation. */
  public send(message: OutgoingMessage): void {
    this.conversationIdStore
      .get()
      .pipe(
        mergeMap((conversationId: ConversationId) =>
          this.conversationApiClient.postMessage(conversationId, message)
        ),
        finalize(() => this.messagesFetcher.awaitIncomingMessages())
      )
      .subscribe({
        error: (error) => console.error("Unable to send message", error),
      }); // will be unsubscribed automatically
  }

  /** Send first evaluation on message*/
  public sendEvaluation(evaluation: EvaluationOutgoingMessage, messageId: number): void {
    this.conversationIdStore
      .get()
      .pipe(
        mergeMap((conversationId: ConversationId) =>
          this.conversationApiClient.postEvaluation(conversationId, messageId, evaluation)
        ),
        finalize(() => this.messagesFetcher.awaitIncomingMessages())
      )
      .subscribe({
        error: (error) => console.error("Unable to send evaluation", error),
      }); // will be unsubscribed automatically
  }

  /** Send explanations on a negative evaluation*/
  public sendEvaluationExplanations(
    evaluation: EvaluationOutgoingMessage,
    messageId: number
  ): void {
    this.conversationIdStore
      .get()
      .pipe(
        mergeMap((conversationId: ConversationId) =>
          this.conversationApiClient.putEvaluation(conversationId, messageId, evaluation)
        ),
        finalize(() => this.messagesFetcher.awaitIncomingMessages())
      )
      .subscribe({
        error: (error) => console.error("Unable to send evaluation", error),
      }); // will be unsubscribed automatically
  }
}
