<p i18n="@@score-form.form.legend">-</p>

<div [formGroup]="scoreForm" class="d-flex flex-column" style="flex: 1">
  <p class="mb-1 text-muted">
    <label for="description" i18n="@@score-form.field.description.label">-</label><span> *</span>
  </p>
  <mat-form-field appearance="fill" class="mb-2">
    <textarea
      matInput
      cdkTextareaAutosize
      maxlength="1000"
      cdkAutosizeMinRows="5"
      cdkAutosizeMaxRows="5"
      formControlName="description"
      id="description"
      required
      data-testid="description"
    ></textarea>
    <mat-error
      role="alert"
      *ngIf="scoreForm.controls['description'].errors"
      i18n="@@field.required.error"
      >-</mat-error
    >
  </mat-form-field>

  <label for="fileInput" class="mb-1 text-muted" i18n="@@score-form.field.file.label">-</label>
  <input
    type="file"
    id="fileInput"
    (change)="onFileChange($event)"
    class="d-none"
    data-testid="file-input"
    #fileUpload
  />
  <button
    (click)="fileUpload.click()"
    formButton="primary"
    type="button"
    style="width: fit-content"
    i18n="@@score-form.field.file.button"
  >
    -
  </button>
  <span class="text-center mb-4">{{ fileName }}</span>
</div>
