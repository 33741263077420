import { inject, InjectionToken } from "@angular/core";
import { PersistedChatOpenState } from "./open.store";
import { APP_CONFIG, PersistentStore } from "app/core";
import { persistentUtil } from "../../core/util/persistent.util";

/** Dependency injection tokens */
export const CHAT_OPEN_PERSISTENT_STORE = new InjectionToken<
  PersistentStore<PersistedChatOpenState>
>("CHAT_OPEN_PERSISTENT_STORE", {
  factory: () => {
    const config = inject(APP_CONFIG);
    const storeId = config.botName + "botopenstate";
    const modelKeys = ["open"];
    const timeToLive = 3600000;
    //Name of event send by loader
    const eventId = "open";

    return persistentUtil.buildPersistentStore<PersistedChatOpenState>(
      storeId,
      modelKeys,
      timeToLive,
      eventId
    );
  },
});

export const CHAT_CLOSING_DELAY = new InjectionToken<number>("CHAT_CLOSING_DELAY", {
  factory: () => 2000,
});
