import { Observable, of } from "rxjs";
import { PersistentStore } from "./persistent-store.util";
import { persistentUtil } from "./persistent.util";

const timestampKey = "timestamp";

/** A persistent store on the local storage */
export class LocalStorageStore<Model> implements PersistentStore<Model> {
  /**
   * The constructor of the class
   * @param modelKeys The array of the keys to be stored from the model object
   * @param timeToLiveInMs Validity period for stored data, in milliseconds.
   *                       Use 0 to deactivate checkings on duration.
   */
  constructor(
    private localStorageService: Storage,
    private storeId: string,
    private modelKeys: Array<string>,
    private timeToLiveInMs = 0
  ) {}

  /**
   * @return The stored data, or null if the data is not fresh or has never been set
   */
  public get(): Observable<Model | null> {
    const data = {
      timestamp:
        this.localStorageService.getItem(persistentUtil.absoluteKey(timestampKey, this.storeId)) ??
        "",
    };
    if (!persistentUtil.isFresh(data, this.timeToLiveInMs)) {
      return of(null);
    }
    let result = {} as Model;
    let noKeysStored = true;
    for (const key of this.modelKeys) {
      let storedValue;
      try {
        storedValue = this.localStorageService.getItem(
          persistentUtil.absoluteKey(key, this.storeId)
        );
      } catch (error) {
        console.error("Local storage is not available: getItem");
        storedValue = null;
      }
      if (storedValue !== undefined && storedValue !== null) {
        noKeysStored = false;
      }
      (result as any)[key] = storedValue ?? "";
    }
    return noKeysStored ? of(null) : of(result);
  }

  public set(data: Model): void {
    try {
      this.localStorageService.setItem(
        persistentUtil.absoluteKey(timestampKey, this.storeId),
        Date.now().toString()
      );
      for (const key of this.modelKeys) {
        this.localStorageService.setItem(
          persistentUtil.absoluteKey(key, this.storeId),
          (data as any)[key]
        );
      }
    } catch (error) {
      console.error("Local storage is not available: setItem");
    }
  }

  public clear(): void {
    try {
      this.localStorageService.removeItem(persistentUtil.absoluteKey(timestampKey, this.storeId));
      for (const key of this.modelKeys) {
        this.localStorageService.removeItem(persistentUtil.absoluteKey(key, this.storeId));
      }
    } catch (error) {
      console.error("Local storage is not available: removeItem");
    }
  }
}
