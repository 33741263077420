import { AppConfig } from "app/core";
import { commonEnvironment } from "./environment-common";

export const environment: AppConfig = {
  ...commonEnvironment,
  botName: "digi",
  backendBaseUrl: "https://ui-api.digibot-preprod.probayes.net/v0/",
  headerLogoUrl: "/assets/specific/digi/avatar.png",
  headerLogoAnimations: {
    duration: 4000,
    default: {
      path: "/assets/specific/digi/default_animation.json",
      duration: 1500,
    },
  },
  reducedBotLogoUrl: "/assets/specific/digi/chatbot_digiposte_blanc_64x64_JS.svg",
  reducedBotHasWelcomeText: true,
  dimeloNeedsPersonalInfo: true,
  hostDimensions: {
    openHeight: 650,
    openWidth: 450,
    closedHeight: 180,
    closedWidth: 200,
  },
  messageAvatarUrl: "/assets/specific/digi/avatar.png",
  iframeSandbox: true,
};
