import { InjectionToken, inject } from "@angular/core";
import { APP_CONFIG, PersistentStore } from "app/core";
import { persistentUtil } from "app/core/util/persistent.util";
import { PersistedConversationPrefs } from "./prefs.model";

export const CONVERSATION_PREFS_PERSISTENT_STORE = new InjectionToken<
  PersistentStore<PersistedConversationPrefs>
>("CONVERSATION_PREFS_PERSISTENT_STORE", {
  factory: () => {
    const config = inject(APP_CONFIG);
    const storeId =
      config.botName + config.localStoragePrefix + config.botVersion + "_conversation_prefs";

    return persistentUtil.buildPersistentStore<PersistedConversationPrefs>(
      storeId,
      ["keepOnServer", "gdprAccepted"],
      0,
      "conversationPrefs"
    );
  },
});
