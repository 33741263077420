import { AppConfig } from "app/core";
import { MessagesScrollRequester } from "./messages-scroll.requester";
import { MessagesStore } from "../current/messages.store";
import { map, filter } from "rxjs";
import { ScrollRequest } from "app/core/ui";

/**
 * Set up the automatic scrolling to messages in the current conversation block message
 * when new messages or blocks are received
 */
export function setupMessagesScroll(
  config: AppConfig,
  messagesStore: MessagesStore,
  messagesScrollRequester: MessagesScrollRequester
) {
  // no need to unsubscribe on destruction, as the subscription lives during the whole application lifecycle
  return () => {
    // handles the automatic scrolling on messages or on the current block
    if (config.scrollOnMessages) {
      messagesStore.message$
        .pipe(
          // Do not scroll on messages with scrollOn deactivated (for example when personal data message is displayed, we do not want to scroll on first messages)
          filter((message) => message.metadata.scrollOn),
          map((message) => new ScrollRequest(message.metadata.htmlId))
        )
        .subscribe(messagesScrollRequester);
    } else {
      messagesStore.currentBlock$
        .pipe(
          // Do not scroll on messages with scrollOn deactivated (for example when personal data message is displayed, we do not want to scroll on first messages)
          filter((block) => block.messages[0].metadata.scrollOn || block.temporary === true),
          map((block) => new ScrollRequest(block.htmlId, block.temporary ? "end" : "start"))
        )
        .subscribe(messagesScrollRequester);
    }
  };
}
