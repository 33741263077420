import { BotIncomingMessage, SatisfactionIncomingMessage } from "../connector";
import {
  ConversationMessage,
  SatisfactionEmojisMessage,
  MessageMetadata,
} from "../../common/message.model";
import { BotIncomingMessageConverter } from "./message-converter.model";

/** Converts a message used to ask the user to evaluate his satisfaction with emojis */
export class SatisfactionEmojiMessageConverter implements BotIncomingMessageConverter {
  supports(incomingMessage: BotIncomingMessage): boolean {
    const satisfactionData = (incomingMessage as SatisfactionIncomingMessage).content?.extra
      ?.satisfaction;
    return !!(satisfactionData?.useEmojiRatherThanComment && satisfactionData?.emojis?.length > 0);
  }
  convert(incomingMessage: BotIncomingMessage): Array<ConversationMessage> {
    const satisfactionData = (incomingMessage as SatisfactionIncomingMessage).content?.extra
      ?.satisfaction;
    return [
      new SatisfactionEmojisMessage(
        satisfactionData.redir,
        satisfactionData.emojis,
        new MessageMetadata(undefined, "none", "default", true)
      ),
    ];
  }
}
