<div aria-live="polite" aria-atomic="false" aria-relevant="additions text">
  <ng-container *ngIf="!chatLifecycleService.switchedOff">
    <!-- expanded window-->
    <section
      class="d-flex flex-column p-1 h-100"
      style="padding-top: var(--fof-offset-top) !important"
      *ngIf="(chatOpenStore.isOpen$ | async) === true"
      data-testid="chat-box-opened"
    >
      <!-- this wrapper is only used for a11y, so that screen readers read the header and the banner on chat opening -->
      <section>
        <header main class="h-auto" draggable="true"></header>
        <aside headerBanner class="h-auto"></aside>
      </section>
      <main
        chatBox
        class="mh-100 h-50 flex-grow-1 bg-body"
        aria-roledescription="-"
        i18n-aria-roledescription="@@aria.region.label"
      ></main>
    </section>

    <!-- reduced window -->
    <fof-reduced-bot
      *ngIf="(chatOpenStore.isOpen$ | async) === false"
      data-testid="chat-box-closed"
    ></fof-reduced-bot>
  </ng-container>
</div>
