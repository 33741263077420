import { Injectable } from "@angular/core";
import { IncomingConversationBlock, ExecutableContent } from "./connector";

/**
 * A facility to extract script IDs from backend messages.
 */
@Injectable({ providedIn: "root" })
export class ScriptsExtractor {
  filters = {
    open_score_form: (block: IncomingConversationBlock) =>
      (block.inMultipleBlocksSequence && block.lastOfBlocksSequence) ||
      !block.inMultipleBlocksSequence,
  };
  /**
   * @return All the scripts to be executed (denoted by their ID) on the reception of
   *         the passed conversation block. Suggestion scripts are not included.
   */
  extractScriptIds(block: IncomingConversationBlock): string[] {
    return (
      [block.user_message, ...block.bot_message.answers]
        .filter((message) => message.content?.hasOwnProperty("script"))
        .map((message) => (message as { content: ExecutableContent }).content.script)
        .flatMap((script) => (Array.isArray(script) ? script : [script]))
        .filter((script) => !!script)
        //If script is open_score_form, we don't want to open the form if script is found in previous messages (for exemple when we reload page).
        .filter((script) => {
          if (script && this.filters.hasOwnProperty(script)) {
            const filterKey = script as keyof typeof this.filters;
            return this.filters[filterKey](block);
          } else {
            return true;
          }
        }) as string[]
    );
  }
}
