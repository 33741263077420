import { Directive, HostBinding, HostListener, Input } from "@angular/core";
import { AppParentService } from "app/core";
import { ImageMessage } from "./message.model";

/** Displays an image in a conversation, allowing to open a popup containing the image */
@Directive({
  selector: "img[message]",
  standalone: true,
})
export class ImageMessageDirective {
  @Input() message!: ImageMessage;

  @HostBinding("class") ngClass = "d-block img-fluid";
  /** Activates cursor pointer type */
  @HostBinding("attr.role") roleAttribute = "button";
  /** Activates keyboard navigation */
  @HostBinding("attr.tabindex") tabindexAttribute = "0";

  @HostBinding("style") ngStyle = "width : calc(100% - var(--fof-bot-msg-left-space))";

  constructor(private appParentService: AppParentService) {}

  @HostBinding("attr.alt") get altAttribute() {
    return this.message.altText ?? "";
  }
  @HostBinding("attr.src") get srcAttribute() {
    return this.message.url ?? "";
  }

  @HostListener("click") onClick() {
    return this.showPopup();
  }
  @HostListener("keydown.enter") onSelection() {
    return this.showPopup();
  }

  private showPopup() {
    this.appParentService.showImagePopup(this.message.url);
    return false;
  }
}
