import { BotIncomingMessage, SuggestionsContent } from "../connector";
import {
  ConversationMessage,
  MessageMetadata,
  SatisfactionLevelMessage,
} from "../../common/message.model";
import {
  BotIncomingMessageConverter,
  SupportedSatisfactionLevelType,
} from "./message-converter.model";

/**
 * Converts a message used to ask the user to evaluate his satisfaction.
 * Must be called BEFORE the suggestions messages converter.
 */
export class SatisfactionLevelMessageConverter implements BotIncomingMessageConverter {
  constructor(private supportedSatisfactionLevels: readonly SupportedSatisfactionLevelType[]) {}

  supports(incomingMessage: BotIncomingMessage): boolean {
    const hasSuggestions =
      incomingMessage?.content_type === "text" &&
      incomingMessage.content?.hasOwnProperty("suggestions") &&
      (incomingMessage.content as SuggestionsContent).suggestions?.length > 0;
    const suggestions = hasSuggestions
      ? (incomingMessage.content as SuggestionsContent).suggestions
      : [];

    const isStars = hasSuggestions && incomingMessage.content?.hasOwnProperty("stars");

    const isSatisfactionLevel =
      hasSuggestions &&
      this.supportedSatisfactionLevels.some(
        (supported) =>
          supported.text === suggestions[0].text && supported.intent === suggestions[0].intent
      );
    return isStars || isSatisfactionLevel;
  }
  convert(incomingMessage: BotIncomingMessage): Array<ConversationMessage> {
    const suggestionsContent = incomingMessage.content as SuggestionsContent;
    const suggestions = [...suggestionsContent.suggestions];
    if (suggestions[0].intent === "evaluation_up_reponse") {
      // the suggestions are in decreasing order
      suggestions.reverse();
    }
    return [
      new SatisfactionLevelMessage(
        suggestions,
        new MessageMetadata(suggestionsContent.animation, "none", "default", true)
      ),
    ];
  }
}
