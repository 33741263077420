import { Inject, Injectable, Signal, WritableSignal, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { APP_CONFIG, AppConfig, PersistentStore } from "app/core";
import { USER_RIGHTS_PERSISTENT_STORE } from "./di-tokens";
import { UserRights } from "./user.model";

/** A store to access rights for the current user. */
@Injectable({ providedIn: "root" })
export class UserRightsStore {
  private readonly _canWrite: WritableSignal<boolean>;
  private persisted: Signal<UserRights | null | undefined>;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    @Inject(USER_RIGHTS_PERSISTENT_STORE)
    private persistentStore: PersistentStore<UserRights>
  ) {
    if (config.hasResetConversationOnReload) {
      persistentStore.clear();
    }
    this.persisted = toSignal(this.persistentStore.get());
    // true by default
    this._canWrite = signal(this.persisted() ? this.persisted()?.canWrite === "true" : true);
  }

  /** @return A signal on the user right to write */
  public get canWriteSignal(): Signal<boolean> {
    return this._canWrite;
  }

  /** @return Whether the user can currently write */
  public get canWrite(): boolean {
    return this._canWrite();
  }

  /** Sets whether the user can write */
  public set canWrite(newValue: boolean) {
    const newValueAsString = newValue + "";
    let data = {
      ...this.persisted(),
      canWrite: newValueAsString,
    };
    this.persistentStore.set(data);
    this._canWrite.set(newValue);
  }
}
