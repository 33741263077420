// Loaded by each bot

import { AppConfig } from "app/core";

// séparer ce qui est specifique au design et ce qui diffère sur les envrionnements de déploiement
export const commonEnvironment: AppConfig = {
  production: true,
  lang: "fr",
  botName: "arole",
  botVersion: "",
  backendBaseUrl: "https://ui-api.digibot-prod.probayes.net/v0/",
  opened: false,
  localStoragePrefix: "",
  distLocation: "",
  scenario: "",

  checkAvailability: false,
  hasMaintenance: false,
  whereToAlwaysShow: "",

  headerActions: [],
  headerLogoUrl: "",
  messageAvatarUrl: "",

  reducedBotHasWelcomeText: false,
  reducedBotLogoUrl: "",
  hasCustomButtonColor: false,
  hasResetConversationOnReload: false,
  hasEvaluationWithStars: false,

  shouldAskToKeepConversation: false,
  needsConsentOnPrivacyTerms: false,
  dimeloLoaderUrl: "",
  dimeloTriggerId: "",
  dimeloNeedsPersonalInfo: false,
  delayInConversationMessagesDisplayInMs: 3500,
  hasConfigurationFromBackend: false,
  convertEmojisToImages: false,

  scrollOnMessages: true,
  //* !if changed, check there is no regression on screen reader behavior and also on auto scroll on image or ending buttons*/
  focusRequestsDelay: 300,
  currentBlockAriaVisibleAfter: 350,

  hostDimensions: {
    openHeight: 650,
    openWidth: 450,
    closedHeight: 130,
    closedWidth: 180,
    closedMobileHeight: 100,
    closedMobileWidth: 100,
  },

  iframeSandbox: false,
};
