import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PieceJointe } from "../outgoing/outgoing-message.model";

/** Service providing utilitary functions to upload file*/
@Injectable({ providedIn: "root" })
export class UploadFileUtil {
  /**  Upload file and encode file to base64 */
  upload(file: File): Observable<PieceJointe> {
    return new Observable<PieceJointe>((observer) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onloadend = () => {
        const uploadedFile = {
          body: (reader.result as string).split(",").pop() as string,
          filename: file.name,
          sujet: "Pièce jointe",
        };
        observer.next(uploadedFile);
        observer.complete();
      };
      reader.onerror = (error) => {
        observer.error(error);
      };
    });
  }
}
