import { InjectionToken, inject } from "@angular/core";
import { APP_CONFIG, CountDown, PersistentStore } from "app/core";
import { ConversationId } from "./conversation-id.model";
import { MessagesFetcher } from "./messages.fetcher";
import { MessagesPollingFetcher } from "./messages.polling.fetcher";
import { ConversationApiClient } from "./conversation.apiclient";
import { ConversationUtil } from "./conversation.util";
import { ConversationIdStore } from "./conversation-id.store";
import { persistentUtil } from "app/core/util/persistent.util";

export const CONVERSATION_ID_PERSISTENT_STORE = new InjectionToken<PersistentStore<ConversationId>>(
  "CONVERSATION_ID_PERSISTENT_STORE",
  {
    factory: () => {
      const appConfig = inject(APP_CONFIG);
      const storeId =
        appConfig.botName + appConfig.localStoragePrefix + appConfig.botVersion + "_conversation";
      const timeToLive = 3600000;
      const eventId = "conversationId";
      return persistentUtil.buildPersistentStore<ConversationId>(
        storeId,
        ["id", "token"],
        timeToLive,
        eventId
      );
    },
  }
);

export const MESSAGES_FETCHER = new InjectionToken<MessagesFetcher>("MESSAGES_FETCHER", {
  factory: () =>
    // polls for 60 seconds, each second
    new MessagesPollingFetcher(
      1000,
      new CountDown(60),
      inject(ConversationApiClient),
      inject(ConversationIdStore),
      new ConversationUtil()
    ),
});
