import { AppHostService, AppParentService } from "app/core";
import { map, Observable, Subject, tap } from "rxjs";
import { persistentUtil } from "app/core/util/persistent.util";

const timestampKey = "timestamp";

export type ModelWithTimestamp<Model> = Model & { timestamp: string };

/** Service used only if config iframeSandbox = true.
 * It asks parent service to get value from local storage and received this value from host service.
 * - subject$ contains persisted value and complete each time value is received.
 * - transferDataSubject$ never complete and is used only to listen to values received from loader in host service
 */
export class ExternalPersitentStore<Model> {
  private subject$?: Subject<ModelWithTimestamp<Model> | null>;
  private transferDataSubject$ = new Subject<unknown | null>();

  constructor(
    private parentService: AppParentService,
    private appHostService: AppHostService,
    private storeId: string,
    private modelKeys: Array<string>,
    private timeToLiveInMs = 0,
    private eventId: string
  ) {
    this.transferDataSubject$.subscribe({
      next: (value) => {
        if (this.subject$) {
          this.subject$.next(value as ModelWithTimestamp<Model>);
          this.subject$.complete();
          this.subject$ = undefined;
        }
      },
    });

    this.appHostService.register(this.eventId, this.transferDataSubject$);
  }
  get(): Observable<Model | null> {
    if (!this.subject$) {
      this.subject$ = new Subject<ModelWithTimestamp<Model> | null>();
      for (const key of this.modelKeys) {
        this.parentService.getLocalStorageItem(persistentUtil.absoluteKey(key, this.storeId));
      }
    }
    return this.subject$.pipe(
      map((value) => (persistentUtil.isFresh(value, this.timeToLiveInMs) ? value : null))
    );
  }

  set(data: Model | null) {
    const timestamp = Date.now().toString();
    // Wait that first value from get has been received before setting new one.
    if (!this.subject$) {
      for (const key of this.modelKeys) {
        this.parentService.setLocalStorageItem(persistentUtil.absoluteKey(key, this.storeId), {
          ...data,
          timestamp,
        });
      }
    } else {
      console.log("SET ignored because getter is not ready", data);
    }
  }

  clear() {
    for (const key of this.modelKeys) {
      this.parentService.setLocalStorageItem(persistentUtil.absoluteKey(key, this.storeId), null);
    }
  }
}
