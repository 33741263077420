<div
  class="h-100 flex-shrink-1 d-flex flex-column px-3 py-4"
  style="
    background-color: var(--bs-body-bg);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  "
  data-testid="messages-scroll-container"
>
  <fof-personal-data-message></fof-personal-data-message>
  <fof-history data-testid="history" aria-hidden="true"></fof-history>
  <fof-current-conversation-block
    aria-live="polite"
    role="log"
    aria-atomic="false"
    aria-relevant="additions text"
    data-testid="current-conversation-block"
    [autoscroll]="currentScrollRequests()"
  ></fof-current-conversation-block>
</div>
<fof-user-input
  data-testid="user-input"
  class="flex-shrink-0 border-top border-bottom-4 px-4 py-3"
  style="--bs-border-color: var(--fof-border-color); border-bottom-width: 0"
></fof-user-input>
