import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormButtonComponent } from "app/core/ui/form-button-component";
import { RequestFocusDirective } from "app/core/ui";

@Component({
  selector: "fof-score-form-part2",
  templateUrl: "./template.html",
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    FormButtonComponent,
    RequestFocusDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreFormPart2Component {
  @Input() scoreForm!: FormGroup;
}
