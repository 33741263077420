import { ChangeDetectionStrategy, Component, HostBinding, Inject, signal } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { NgFor, NgIf } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormButtonComponent } from "app/core/ui/form-button-component";
import { ScoreFormStore } from "../score-form-store";
import { IconButtonComponent } from "app/core/ui";
import { ScoreFormEntries } from "../score-form.model";
import { PointButtonDirective } from "app/core/ui/point-button-directive";

import { AppConfig, APP_CONFIG } from "app/core";
import { timer } from "rxjs";
import { ScoreFormPart1Component } from "../score-form-part1-component";
import { ScoreFormPart2Component } from "../score-form-part2-component";

@Component({
  selector: "fof-score-form",
  templateUrl: "./template.html",
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgIf,
    NgFor,
    FormButtonComponent,
    IconButtonComponent,
    PointButtonDirective,
    ScoreFormPart1Component,
    ScoreFormPart2Component,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

/** A component displaying a form to obtain user information (name, email, employer etc..) */
export class ScoreFormComponent {
  scoreFormPart1 = new FormGroup({
    description: new FormControl("", Validators.required),
    file: new FormControl<File | null>(null),
  });
  scoreFromPart2 = new FormGroup({
    lastname: new FormControl("", Validators.required),
    firstname: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
  });

  scoreForm = new FormGroup({
    part1: this.scoreFormPart1,
    part2: this.scoreFromPart2,
  });

  protected activeStepId = 0;

  protected readonly availableForAria = signal<boolean>(false);

  /** Styles to be applied to previous and next buttons */
  protected buttonsStyles = {
    color: "white",
    bgColor: "var(--fof-primary-color)",
    borderColor: "var(--fof-primary-color)",
    colorOnHover: "var(--fof-primary-color)",
    bgColorOnHover: "white",
    borderColorOnHover: "var(--fof-primary-color)",
  };

  @HostBinding("class") hostClasses = "d-flex flex-column justify-content-between h-100";

  constructor(
    private scoreFormStore: ScoreFormStore,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.makeAvailableForAria();
  }

  onSubmit() {
    if (this.scoreForm.invalid) {
      return;
    }
    const result = {
      ...this.scoreForm.value.part1,
      ...this.scoreForm.value.part2,
    };
    this.scoreFormStore.publish(result as ScoreFormEntries);
  }

  onCancel() {
    this.scoreForm.reset();
    this.scoreFormStore.cancelRequest();
  }

  activateStep(index: number) {
    this.activeStepId = index;
    this.availableForAria.set(false);
    this.makeAvailableForAria();
  }

  private makeAvailableForAria() {
    timer(this.appConfig.currentBlockAriaVisibleAfter).subscribe(() => {
      this.availableForAria.set(true);
    });
  }
}
