import { BotIncomingMessage, CarouselItemIncoming, CarouselIncomingMessage } from "../connector";
import {
  CarouselItem,
  CarouselMessage,
  ConversationMessage,
  MessageMetadata,
  UrlRedirect,
} from "../../common/message.model";
import { BotIncomingMessageConverter } from "./message-converter.model";

/**
 * Converts a bot carousel message.
 */
export class CarouselMessageConverter implements BotIncomingMessageConverter {
  supports(incomingMessage: BotIncomingMessage): boolean {
    return incomingMessage?.content_type === "carousel";
  }
  convert(incomingMessage: BotIncomingMessage): Array<ConversationMessage> {
    const result = new Array<ConversationMessage>();
    const incomingCarouselMessage = incomingMessage as CarouselIncomingMessage;

    result.push(
      new CarouselMessage(
        this.adaptIncomingToCarouselItems(incomingCarouselMessage.content.items),
        new MessageMetadata("", "none", "default", true)
      )
    );
    return result;
  }

  private adaptIncomingToCarouselItems(
    incomingItems: Array<CarouselItemIncoming>
  ): Array<CarouselItem> {
    return incomingItems.map((incomingItem) => {
      return new CarouselItem(
        incomingItem.image_url ?? "",
        incomingItem.image_alt ?? "image",
        incomingItem.title ?? "",
        incomingItem.description ?? undefined,
        this.adaptIncomingActionKey(incomingItem.action || undefined)
      );
    });
  }

  private adaptIncomingActionKey(action: CarouselItemIncoming["action"]): CarouselItem["action"] {
    if (action && action.external_url) {
      return new UrlRedirect(action.text ?? "", action.external_url);
    } else if (action && action.intent && action.text) {
      return {
        text: action.text ?? "",
        color: action.color ?? "",
        intent: action.intent,
      };
    }
    return undefined;
  }
}
