import { inject } from "@angular/core";
import { AppHostService } from "../app-host.service";
import { APP_CONFIG } from "../config";
import { AppParentService } from "../parent";
import { ExternalPersitentStore, ModelWithTimestamp } from "./persistent-store-external.util";
import { LocalStorageStore } from "./persistent-store-local.util";

/**
 * A service providing utils for persitent storage manipulation
 * used in external or local persistent store.
 */
class PersistentUtil {
  absoluteKey(key: string, storeId: string): string {
    if (storeId) {
      return storeId + "_" + key;
    }
    return key;
  }

  /** @return Whether the stored data is fresh */
  isFresh(data: ModelWithTimestamp<unknown> | null, timeToLiveInMs: number): boolean {
    // check on timestamp is deactivated
    if (timeToLiveInMs === 0) {
      return true;
    }
    const timestampAsString = data?.timestamp;

    if (!timestampAsString || data === null) {
      return false;
    }
    const timestamp = parseInt(timestampAsString, 10);
    if (isNaN(timestamp)) {
      return false;
    }
    const outdatedTimestamp = Date.now() - timeToLiveInMs;
    return timestamp > outdatedTimestamp;
  }

  buildPersistentStore<Model>(
    storeId: string,
    modelKeys: Array<string>,
    timeToLive: number,
    eventId: string
  ) {
    //If iframe has sandbox attribute, the app has no direct access to localStorage, and we need to ask loader to get localStorage content.

    const appConfig = inject(APP_CONFIG);
    if (appConfig.iframeSandbox) {
      const parentService = inject(AppParentService);
      return new ExternalPersitentStore<Model>(
        parentService,
        inject(AppHostService),
        storeId,
        modelKeys,
        timeToLive,
        eventId
      );
    }

    return new LocalStorageStore<Model>(window.localStorage, storeId, modelKeys, timeToLive);
  }
}

export const persistentUtil = new PersistentUtil();
