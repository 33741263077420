import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OutgoingMessageService } from "app/chat/outgoing";
import { RequestFocusDirective, ScrollRequest } from "app/core/ui";
import { NgIf, NgClass, NgFor } from "@angular/common";
import {
  BotTextMessage,
  MessageMetadata,
  MessagePositionType,
  UserTextMessage,
} from "app/chat/common/message.model";
import { BotTextMessageComponent } from "app/chat/common/bot-text-message-component";
import { EvaluationQuestionnaire } from "app/chat/common/message.model";
import { MessagesScrollRequester } from "app/chat/common/messages-scroll.requester";
import { FormButtonComponent } from "app/core/ui/form-button-component";
import { UserTextMessageComponent } from "app/chat/common/user-text-message-component";
import { AppHostService } from "app/core";
import { EvaluationButtonDirective } from "./evaluation-button.directive";

/** The possible steps of the evaluation */
type EvaluationSteps =
  | "no_answer"
  | "positive_evaluation"
  | "negative_evaluation"
  | "negative_evaluation_explanation";

/**
 * Displays an interactive questionnaire enabling the user to
 * evaluate a single chatbot answer
 */
@Component({
  selector: "fof-answer-evaluation",
  templateUrl: "./template.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    BotTextMessageComponent,
    UserTextMessageComponent,
    FormButtonComponent,
    RequestFocusDirective,
    EvaluationButtonDirective,
  ],
})
export class AnswerEvaluationComponent {
  private _data!: EvaluationQuestionnaire;

  protected introductiveEvaluationBotMessage!: BotTextMessage;
  protected afterPositiveEvaluationBotMessage!: BotTextMessage;
  protected afterNegativeEvaluationBotMessage!: BotTextMessage;
  protected furtherEvaluationUserMessage!: UserTextMessage;
  protected afterFurtherEvaluationBotMessage!: BotTextMessage;

  /** ID of the HTML element displayed after a negative answer. Used for scrolling. */
  protected negativeAnswerScrollAnchor = "AfterNegativeEvaluationAnchor";
  protected positiveAnswerScrollAnchor = "AfterPositiveEvaluationAnchor";
  protected furtherEvaluationScrollAnchor = "FurtherEvaluationAnchor";

  /** The current evaluation step */
  step: EvaluationSteps = "no_answer";

  constructor(
    private outgoingMessageService: OutgoingMessageService,
    private messagesScrollRequester: MessagesScrollRequester,
    private appHostService: AppHostService
  ) {}

  get data() {
    return this._data;
  }

  @Input()
  set data(newValue: EvaluationQuestionnaire) {
    this._data = newValue;
    this.updateStep("no_answer");
    this.introductiveEvaluationBotMessage = this.makeBotMessage(this._data.questionText, "start");
    this.afterPositiveEvaluationBotMessage = this.makeBotMessage(
      this._data.textOnPositiveEvaluation,
      "end"
    );
    this.afterNegativeEvaluationBotMessage = this.makeBotMessage(
      $localize`:@@answer-evaluation.details.request:`,
      "end"
    );
    this.furtherEvaluationUserMessage = UserTextMessage.makeInteractionResultMessage("", "");
    this.afterFurtherEvaluationBotMessage = this.makeBotMessage(
      this._data.textOnNegativeEvaluation,
      "end"
    );
  }

  private updateStep(newValue: EvaluationSteps) {
    this.step = newValue;
  }

  private makeBotMessage(text: string, position: MessagePositionType = "default") {
    return BotTextMessage.makeMessage(text, new MessageMetadata("", "none", position, true));
  }

  get isAnswered() {
    return this.step !== "no_answer";
  }

  onPositiveEvaluation(): void {
    this.updateStep("positive_evaluation");
    this.messagesScrollRequester.next(new ScrollRequest(this.positiveAnswerScrollAnchor, "start"));

    this.outgoingMessageService.sendEvaluationMessage({
      message_id: this.data.stepId,
      is_user_evaluation_positive: true,
    });
    this.appHostService.focusOnUserInput$.next(Date.now());
  }

  /**
   * Send an evaluation with no negative explanation,
   * and display the negative explanations buttons so that the
   * user can click one of them if he wants
   */
  onNegativeEvaluation(): void {
    this.updateStep("negative_evaluation");
    this.messagesScrollRequester.next(new ScrollRequest(this.negativeAnswerScrollAnchor, "start"));
    this.outgoingMessageService.sendEvaluationMessage({
      message_id: this.data.stepId,
      is_user_evaluation_positive: false,
    });
  }

  /** Send a negative evaluation with the clicked explanation */
  onNegativeEvaluationExplanation(key: string, text: string): void {
    this.updateStep("negative_evaluation_explanation");
    this.outgoingMessageService.sendEvaluationExplanationsMessage({
      message_id: this.data.stepId,
      is_user_evaluation_positive: false,
      negative_explanation: { key, text },
    });
    this.furtherEvaluationUserMessage = UserTextMessage.makeInteractionResultMessage(text, "");
    this.messagesScrollRequester.next(
      new ScrollRequest(this.furtherEvaluationScrollAnchor, "start")
    );
    this.appHostService.focusOnUserInput$.next(Date.now());
  }
}
