import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { APP_CONFIG, AppConfig } from "app/core";
import { ConversationId } from "./conversation-id.model";
import { EvaluationOutgoingMessage, OutgoingMessage } from "app/chat/outgoing";
import { IncomingConversationBlock } from "./incoming.model";

/** Payload type sent by the server */
export interface BackendConversationId {
  conversation_id: string;
  token: string;
}

/** Client to access the endpoints related to a conversation in a chatbot */
@Injectable({ providedIn: "root" })
export class ConversationApiClient {
  private endPoint: string;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient
  ) {
    this.endPoint = config.backendBaseUrl;
  }

  /**
   * Initiate a new conversation.
   * @return ID data of the new conversation
   */
  public create(): Observable<ConversationId> {
    const url = `${this.endPoint}webhook/chat/authenticate`;
    let options = {};
    if (this.config.useSecretToken) {
      options = {
        headers: new HttpHeaders().append("x-pby-secret", this.config.authSecretToken ?? ""),
      };
    }
    return this.http.get<BackendConversationId>(url, options).pipe(
      map((payload) => ({
        id: payload.conversation_id,
        token: payload.token,
      }))
    );
  }

  /**
   * Retrieves the data of the conversation denoted by the passed ID,
   * optionnally returning only messages since the passed timestamp, if greater than 0
   * @param sinceTimestamp The timestamp from which messages should be retrieved. Ignored if 0.
   *                       Corresponds to the UTC number of milliseconds since the epoch.
   */
  public retrieve(conversationId: ConversationId, sinceTimestamp = 0) {
    const url = `${this.endPoint}webhook/chat/${conversationId.token}`;
    const params = this.toQueryParams(conversationId);
    if (sinceTimestamp > 0) {
      params.last_message_date = sinceTimestamp;
    }
    return this.http.get<IncomingConversationBlock[]>(url, { params });
  }

  /** Posts a new message into the conversation denoted by the passed ID */
  public postMessage(
    conversationId: ConversationId,
    message: OutgoingMessage
  ): Observable<unknown> {
    const url = `${this.endPoint}webhook/chat/${conversationId.token}`;
    return this.http.post(url, message, {
      params: this.toQueryParams(conversationId),
    });
  }

  /** Posts a new evaluation on specific message */
  public postEvaluation(
    conversationId: ConversationId,
    messageId: number,
    evaluation: EvaluationOutgoingMessage
  ): Observable<unknown> {
    const url = `${this.endPoint}conversations/${conversationId.id}/messages/${messageId}/evaluations`;
    return this.http.post(url, evaluation, {
      headers: new HttpHeaders().append("Authorization", conversationId.token ?? ""),
    });
  }

  /** Update an existing evaluation on specific message */
  public putEvaluation(
    conversationId: ConversationId,
    messageId: number,
    evaluation: EvaluationOutgoingMessage
  ): Observable<unknown> {
    const url = `${this.endPoint}conversations/${conversationId.id}/messages/${messageId}/evaluations`;
    return this.http.put(url, evaluation, {
      headers: new HttpHeaders().append("Authorization", conversationId.token ?? ""),
    });
  }

  private toQueryParams(conversationId: ConversationId): {
    conversation_id: string;
    last_message_date?: number;
  } {
    return { conversation_id: conversationId.id };
  }
}
