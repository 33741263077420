<img
  [src]="slide.imageUrl"
  [alt]="slide.imageAlt"
  class="w-100 bg-dark rounded-top"
  style="object-fit: cover; min-height: 7.8rem; max-height: 7.8rem"
/>
<div class="p-3 pb-1 d-flex flex-column h-100" style="max-height: 8rem; overflow-y: auto">
  <h5 class="fw-bold">{{ slide.title }}</h5>
  <p class="mb-0">{{ slide.description }}</p>
</div>
<div
  *ngIf="slide.action"
  class="d-flex justify-content-center align-item-end p-1 border-top"
  style="min-height: 3rem"
>
  <a
    *ngIf="isUrlRedirect(slide.action)"
    [href]="slide.action.externalUrl"
    target="_blank"
    role="button"
    class="d-flex align-items-center py-2 w-80"
    data-testid="urlRedirect"
  >
    {{ slide.action.text }}
    <i class="fof-icon-primary fof-icon-external-link ms-2 p-2"></i>
  </a>

  <button
    *ngIf="isSuggestion(slide.action)"
    [suggestion]="slide.action"
    [disabled]="allowInteraction"
    data-testid="suggestion"
  ></button>
</div>
