import { Injectable } from "@angular/core";
import { Observable, of, Subject, switchMap, tap } from "rxjs";
import { ChatBoxContentSwitcher } from "../common/chat-box-content.switcher";
import { OutgoingMessageService } from "../outgoing";
import { ScoreFormResult } from "../outgoing/outgoing-message.model";
import { ScoreFormEntries } from "./score-form.model";
import { UploadFileUtil } from "./upload-file.util";

/** A store to retrieve score form from the user. Score form is used to create ticket for digiposte */
@Injectable({ providedIn: "root" })
export class ScoreFormStore {
  private value$ = new Subject<ScoreFormResult>();

  constructor(
    private chatBoxContentSwitcher: ChatBoxContentSwitcher,
    private outgoingMessageService: OutgoingMessageService,
    private uploadFileUtil: UploadFileUtil
  ) {}

  /** @return An observable to score form*/
  get(): Observable<ScoreFormResult> {
    return this.value$.asObservable();
  }

  /**  Convert score form entries to expected API format */
  private convert(values: ScoreFormEntries): Observable<ScoreFormResult> {
    console.log("convert", values);
    if (values.file) {
      return this.uploadFileUtil.upload(values.file).pipe(
        switchMap((uploadedFile) => {
          return of({
            prenomclient: values.firstname,
            nomclient: values.lastname,
            email: values.email,
            description: values.description,
            piecejointe: uploadedFile ? [uploadedFile] : undefined,
          });
        })
      );
    } else {
      return of({
        prenomclient: values.firstname,
        nomclient: values.lastname,
        email: values.email,
        description: values.description,
        piecejointe: undefined,
      });
    }
  }

  /** Publishes new score form */
  publish(values: ScoreFormEntries) {
    //Wait for file to be loaded before sending result
    this.convert(values)
      .pipe(
        tap((convertedValues) => {
          this.value$.next(convertedValues);

          this.chatBoxContentSwitcher.release();
          this.outgoingMessageService.sendScoreForm(convertedValues);
        })
      )
      .subscribe({
        error: (err) => console.error("Erreur lors de la lecture du fichier", err),
      });
  }

  /** Requests score form  */
  request() {
    this.chatBoxContentSwitcher.request("score-form");
  }

  /** Cancels the request for score form */
  cancelRequest() {
    this.outgoingMessageService.cancelScoreForm();
    this.chatBoxContentSwitcher.release();
  }
}
