import { IncomingConversationBlock } from "./incoming.model";

/** Service providing utilitary functions */
export class ConversationUtil {
  /** @return A negative number if item1 is less than item2, a positive number
   *  if item1 is greater than item2, and zero if they are equal */
  private sort(item1: IncomingConversationBlock, item2: IncomingConversationBlock) {
    const timestamp1 = item1.hook_reception_date ?? 0;
    const timestamp2 = item2.hook_reception_date ?? 0;
    if (timestamp1 < timestamp2) {
      return -1;
    }
    if (timestamp1 > timestamp2) {
      return 1;
    }
    return 0;
  }

  /**
   * Sorts the passed array in place, according to their timestamp
   * @return The most recent timestamp in the array */
  public sortBlocks(items: IncomingConversationBlock[]): number {
    if (items?.length > 0) {
      items.sort(this.sort.bind(this));
      return items[items.length - 1].hook_reception_date ?? 0;
    }
    return 0;
  }

  /**
   * Define for each block if is is the lastOfBlocksSequence and if it is inMultupleBlocksSequence
   */
  public addSequenceInfoOnBlocks(blocks: IncomingConversationBlock[]) {
    blocks.forEach((block, index) => {
      block.inMultipleBlocksSequence = blocks.length > 1;
      block.lastOfBlocksSequence = index === blocks.length - 1;
    });
    return blocks;
  }
}
