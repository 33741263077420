import { Injectable, Inject } from "@angular/core";
import { APP_CONFIG, AppConfig, PersistentStore } from "app/core";
import { ConversationPrefs, PersistedConversationPrefs } from "./prefs.model";
import { CONVERSATION_PREFS_PERSISTENT_STORE } from "./di-tokens";
import { BehaviorSubject, filter, tap } from "rxjs";

/** A store for the preferences related to chat conversations. */
@Injectable({ providedIn: "root" })
export class ConversationPrefsStore {
  private value$ = new BehaviorSubject<PersistedConversationPrefs>({
    keepOnServer: "true",
    gdprAccepted: "true",
  });

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    @Inject(CONVERSATION_PREFS_PERSISTENT_STORE)
    private persistentStore: PersistentStore<PersistedConversationPrefs>
  ) {
    this.persistentStore
      .get()
      .pipe(
        filter((value) => !!value),
        tap((value) => this.value$.next(value as PersistedConversationPrefs))
      )
      .subscribe();

    if (config.hasResetConversationOnReload) {
      persistentStore.clear();
    }
  }

  private getPersistedOrDefault(): PersistedConversationPrefs {
    return this.value$.getValue();
  }

  /** @return The current conversations' preferences */
  get(): ConversationPrefs {
    const persisted = this.getPersistedOrDefault();
    return {
      keepOnServer: persisted.keepOnServer === "true",
      gdprAccepted: persisted.gdprAccepted === "true",
    };
  }

  /** Force the preferences cleaning */
  public clear(): void {
    this.persistentStore.clear();
  }

  /** Sets whether the conversation should be kept on the server */
  setKeepOnServer(newValue: boolean): void {
    const persisted = this.getPersistedOrDefault();
    persisted.keepOnServer = newValue + "";
    this.persistentStore.set(persisted);
  }

  /** Sets whether the user accepted the GDPR terms */
  setGdprAccepted(newValue: boolean): void {
    const persisted = this.getPersistedOrDefault();
    persisted.gdprAccepted = newValue + "";
    this.persistentStore.set(persisted);
  }
}
