import { Injectable } from "@angular/core";
import { OutgoingMessageService } from "../outgoing";
import { AppHostService, AppParentService } from "app/core";

/** A service used to check for the user consent regarding the external chat or to trigger actions related to this consent. */
@Injectable({ providedIn: "root" })
export class ConsentChecker {
  constructor(
    private hostService: AppHostService,
    private outgoingMessageService: OutgoingMessageService
  ) {}

  checkAndTrigger(): void {
    if (this.hostService.hasConsentOnPrivacyTerms) {
      this.outgoingMessageService.sendIntentionMessage("dimelo_accepted");
    } else {
      this.outgoingMessageService.sendIntentionMessage("check_cookies");
    }
  }
}
