import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormButtonComponent } from "app/core/ui/form-button-component";

@Component({
  selector: "fof-score-form-part1",
  templateUrl: "./template.html",
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    FormButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreFormPart1Component implements OnInit {
  protected fileName = "";

  @Input() scoreForm!: FormGroup;

  ngOnInit() {
    this.fileName = this.scoreForm.value.file?.name;
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    this.scoreForm.patchValue({
      file: input.files[0],
    });
    this.fileName = this.scoreForm.value.file.name;
  }
}
